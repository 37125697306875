$kdl-primary: #5a92b0;
$kdl-accent: #f0be6a;
$kdl-warn: #ff7c75;
$kdl-ok: #5cb330;
$kdl-green: rgb(51 106 119);
$kdl-customer: rgb(155 197 61);
$kdl-factory: rgb(212 168 36);
$kdl-project: rgb(91 192 235);
$kdl-warning: #ffa500;
$kdl-dim-color: #808080;
$kdl-standard-padding: 20px;
$kdl-project-body-width: 100vw;
$kdl-project-body-padding: 20px;
$kdl-project-body-gap: 20px;
$kdl-project-checklist-menu-width: 320px;

@mixin kdl-project-sub-tab-holder {
  padding: 20px;
  width: 100%;
  overflow-x: auto;
}

@mixin kdl-card {
  margin-top: 20px;
  padding: 20px;
}

