@use 'material';
@use 'variables' as vars;
@import 'https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@100;400;500&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;600&';
@import 'https://fonts.googleapis.com/css2?family=Material+Icons+Outlined&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@200;400';

.problem-panel {
  background-color: vars.$kdl-warn;
}

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: sans-serif;
  margin: 0 auto;
}

/* General styling that applies to many pages */

@media print {
  .no-print {
    display: none !important;
  }

  @page {
    margin-bottom: 0;
    margin-top: 0;
  }
}

a {
  color: #fff;
  word-break: break-all;
}

a:visited {
  color: #fff;
}

.dark {
  a {
    color: #000;
    word-break: break-all;
  }

  a:visited {
    color: #000;
  }
}

/* use this to grow */
.filler {
  display: flex;
  flex-grow: 1;
}

.form-full-width {
  width: 100%;
}

.kdl-no-flippers::-webkit-outer-spin-button,
.kdl-no-flippers::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.kdl-small-accordion {
  mat-expansion-panel {
    margin: 0;
    border-radius: 0;
  }

  mat-expansion-panel:first-of-type {
    border-top-left-radius: var(--mat-expansion-container-shape);
    border-top-right-radius: var(--mat-expansion-container-shape);
  }

  mat-expansion-panel:last-of-type {
    border-bottom-left-radius: var(--mat-expansion-container-shape);
    border-bottom-right-radius: var(--mat-expansion-container-shape);
  }

  mat-expansion-panel-header {
    height: 45px !important;
    border-radius: 0;
    font-size: 15px;
  }
}

/*
 this can/should be applied to all tooltips.
 */
.mat-tooltip-extra {
  zoom: 140%;
}

textarea {
  caret-color: #fff !important;
}

input {
  caret-color: #fff !important;
}

.no-max-height {
  max-height: unset !important;
}

.text-error {
  color: vars.$kdl-warn;
}

.text-warning {
  color: vars.$kdl-accent;
}

.hidden {
  display: none;
}

.divider {
  width: 100%;
  border-inline: none;
}

.is-saving {
  pointer-events: none;
  opacity: 0.8;
}

.opacity-transition{
  transition: opacity 0.2s;
}
